<template>
  <div class="ma-9">
    <v-data-table
        :headers="headers"
        :items="feedbacks"
    >
      <template v-slot:item.title_lesson="{ item }">
        {{ item.title_lesson ? item.title_lesson : 'freetalk' }}
      </template>
      <template v-slot:item.name_teacher="{ item }">
        <a :href="`/#/user/student/${item.teacher_id}/info`">{{ item.name_teacher ? item.name_teacher : '' }}</a>
      </template>
      <template v-slot:item.name_student="{ item }">
        <a :href="`/#/user/student/${item.student_id}/info`">{{ item.name_student ? item.name_student : '' }}</a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {GET_LIST_FEEDBACK} from "@/store/feedback.module";

export default {
  //list news
  data() {
    return {
      headers: [
        {text: "Giáo viên", value: "name_teacher"},
        {text: "Học viên", value: "name_student"},
        {text: "Bài học", value: "title_lesson"},
        {text: "Thời gian", value: "date_time_start"},
        {text: "Đánh giá", value: "vote_number"},
        {text: "Phản hồi", value: "feedback"},
      ]
    };
  },

  async created() {
    await this.$store.dispatch(GET_LIST_FEEDBACK);
    await this.$store.dispatch(SET_BREADCRUMB, [{title: "Phản hồi", titledad: 'Phản hồi'}]);
  },

  computed: {
    ...mapGetters({
      feedbacks: "feedbacks"
    })
  },
};
</script>